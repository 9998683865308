(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("tyb"), require("UcodeComponents"), require("React"), require("ReduxForm"), require("ReactDOM"), require("ReactRedux"), require("UcodeUtils"), require("ReactRouterDOM"), require("Redux"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["tyb", "UcodeComponents", "React", "ReduxForm", "ReactDOM", "ReactRedux", "UcodeUtils", "ReactRouterDOM", "Redux", "moment"], factory);
	else if(typeof exports === 'object')
		exports["database"] = factory(require("tyb"), require("UcodeComponents"), require("React"), require("ReduxForm"), require("ReactDOM"), require("ReactRedux"), require("UcodeUtils"), require("ReactRouterDOM"), require("Redux"), require("moment"));
	else
		root["database"] = factory(root["tyb"], root["UcodeComponents"], root["React"], root["ReduxForm"], root["ReactDOM"], root["ReactRedux"], root["UcodeUtils"], root["ReactRouterDOM"], root["Redux"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__11tU__, __WEBPACK_EXTERNAL_MODULE_LA1___, __WEBPACK_EXTERNAL_MODULE_cDcd__, __WEBPACK_EXTERNAL_MODULE_eLzx__, __WEBPACK_EXTERNAL_MODULE_faye__, __WEBPACK_EXTERNAL_MODULE_h74D__, __WEBPACK_EXTERNAL_MODULE_nFx1__, __WEBPACK_EXTERNAL_MODULE_oncg__, __WEBPACK_EXTERNAL_MODULE_rKB8__, __WEBPACK_EXTERNAL_MODULE_wy2R__) {
return 