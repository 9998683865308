import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Button } from 'tyb'

function inject_unount(target) {
  // 改装componentWillUnmount，销毁的时候记录一下
  let next = target.prototype.componentWillUnmount
  target.prototype.componentWillUnmount = function () {
    if (next) next.call(this, ...arguments);
    this.unmount = true
  }
  // 对setState的改装，setState查看目前是否已经销毁
  let setState = target.prototype.setState
  target.prototype.setState = function () {
    if (this.unmount) return;
    setState.call(this, ...arguments)
  }
}



const asyncComponent = (load) => {
  @inject_unount
  class AsyncComponent extends React.Component {
    state = { Component: null }

    componentDidMount() {
      if (this.state.Component) return;
      // 在高阶组件 DidMount 时才去执行网络加载步骤
      load().then(({ default: Component }) => {
        // 代码加载成功，获取到了代码导出的值，调用 setState 通知高阶组件重新渲染子组件
        this.setState({
          Component,
        })
      }).catch((err) => {
        console.error(`Cannot load component in <AsyncComponent />`);
        throw err;
      });
    }

    render() {
      const { Component } = this.state;
      // component 是 React.Component 类型，需要通过 React.createElement 生产一个组件实例
      // return Component ? React.createElement(component) : null;
      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent
}


const Home = () => <Button className="hello">Home</Button>;


const routes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "Root" */'../pages/Root')),
    exact: true
  },
  {
    path: '/home',
    component: Home
  },
  /* 数据字典  Start*/
  {
    path: '/codecustomFields',
    component: asyncComponent(() => import(/* webpackChunkName: "CustomFormat" */'../pages/DataDictionary/CustomFormat')),
    exact: true
  },
  {
    path: '/codecustomFields/view',
    component: asyncComponent(() => import(/* webpackChunkName: "CustomFormatView" */'../pages/DataDictionary/CustomFormat/CustomFormatView')),
    exact: true
  },
  {
    path: '/databasicData/datadealer/detail', // 经销商
    component: asyncComponent(() => import(/* webpackChunkName: "DatadealerDetail" */'../pages/BaseManagement/Datadealer/detail')),
    exact: true,
  },
  {
    path: '/datacodedb',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeBank" */'../pages/BaseManagement/CodeBank')),
    exact: true
  },
  {
    path: '/datacodedb/ip',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeBankIp" */'../pages/BaseManagement/CodeBank/Ip')),
    exact: true
  },  
  {
    path: '/dataaddressBook',
    component: asyncComponent(() => import(/* webpackChunkName: "AddressBook" */'../pages/BaseManagement/OrganizeManagement/AddressBook')),
    exact: true,
  },
  {
    path: '/datarole',
    component: asyncComponent(() => import(/* webpackChunkName: "RolePermission" */'../pages/BaseManagement/OrganizeManagement/RolePermission')),
    exact: true,
  },
  {
    path: '/dataorganization/dataroleedit',
    component: asyncComponent(() => import(/* webpackChunkName: "RolePermissionCreate" */'../pages/BaseManagement/OrganizeManagement/RolePermission/Create')),
    exact: true
  },
  {
    path: '/datauser',
    component: asyncComponent(() => import(/* webpackChunkName: "SubAccount" */'../pages/BaseManagement/OrganizeManagement/SubAccount')),
    exact: true
  },
  {
    path: '/dataorganization/datauseredit',
    component: asyncComponent(() => import(/* webpackChunkName: "SubAccountForm" */'../pages/BaseManagement/OrganizeManagement/SubAccount/Create/SubAccountForm')),
    exact: true
  },
  {
    path: '/datafactory',
    component: asyncComponent(() => import(/* webpackChunkName: "FactoryManagement" */'../pages/BaseManagement/BasicInformationManagement/FactoryManagement')),
    exact: true
  },
   // 证书
   {
    path: '/databasicData/datafactory/Certificate',
    component: asyncComponent(() => import(/* webpackChunkName: "CertificateManagement" */'../pages/BaseManagement/BasicInformationManagement/CertificateManagement/index.js')),
    exact: true
  },
  {
    path: '/dataproduct',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductManagement" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/index')),
    exact: true
  },
  {
    path: '/databasicData/dataproduct/create',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductDetail" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/productDetail')),
    exact: true
  },
  {
    path: '/databasicData/dataproduct/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductDetail" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/productDetail')),
    exact: true
  },
  {
    path: '/datashop',
    component: asyncComponent(() => import(/* webpackChunkName: "TerminalManagement" */'../pages/BaseManagement/BasicInformationManagement/TerminalManagement')),
    exact: true
  },
  {
    path: '/databasicData/datashop/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "TerminalEditForm" */'../pages/BaseManagement/BasicInformationManagement/TerminalManagement/TerminalEditForm')),
    exact: true
  },
  {
    path: '/datadepartment',
    component: asyncComponent(() => import(/* webpackChunkName: "DepartmentManage" */'../pages/BaseManagement/OrganizeManagement/DepartmentManage')),
    exact: true
  },
  {
    path: '/dataorganization/allots',
    component: asyncComponent(() => import(/* webpackChunkName: "DataDistribution" */'../pages/BaseManagement/OrganizeManagement/DepartmentManage/DataDistribution')),
    exact: true
  },
  {
    path: '/datampuser',
    component: asyncComponent(() => import(/* webpackChunkName: "AuthMiniprogramTools" */'../pages/BaseManagement/OrganizeManagement/AuthMiniprogramTools/index')),
    exact: true
  },
  {
    path: '/dataorganization/mpuser',
    component: asyncComponent(() => import(/* webpackChunkName: "MpUser" */'../pages/BaseManagement/OrganizeManagement/AuthMiniprogramTools/mpUser')),
    exact: true
  },

  /** 内部 */
  {
    path: '/SystemLogin',
    component: asyncComponent(() => import(/* webpackChunkName: "SystemLogin" */'../pages/SystemLogin/SystemLogin')),
    exact: true,
  },
  {
    path: '/dataenterprise',
    component: asyncComponent(() => import(/* webpackChunkName: "DataEnterprise" */'../pages/DataEnterprise/index')),
    exact: true,
  },
  {
    path: '/dataenterprise/allot',
    component: asyncComponent(() => import(/* webpackChunkName: "DataEnterpriseAllot" */'../pages/DataEnterprise/AllotIndex')),
    exact: true,
  },
  {
    path: '/dataenterprise/timeConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "DataEnterpriseAllot" */'../pages/DataEnterprise/components/TimeConfig.tsx')),
    exact: true,
  },
  

  /** 内部 */
  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
];

const codeRoutes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "ExtractionCodePackage" */'../pages/Ucode/ExtractionCode/ExtractionCodePackage')),
    exact: true,
  },
  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
]



// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        // pass the sub-routes down to keep nesting
        <Component {...props} />
        // <Component {...props} name={name}></Component>
      )}
    />
  );
}

const AppRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    {/* <HashRouter> */}
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
    {/* </HashRouter> */}
  </div>
);

const CodeRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Switch>
      {codeRoutes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  </div>
);




export { AppRouter, routes, CodeRouter };
